import * as Config from "@/config/constants";
import dayjs from 'dayjs';

function billingMarker4Modal(bmarker) {
    return {
        id: bmarker.id,
        project: {
            id: bmarker.projectId,
            name: bmarker.projectName
        },
        billingDate: dayjs(bmarker.billingDate.date).format(Config.DATE_FORMAT),
        title: bmarker.title,
        description: bmarker.description,
        customerName: bmarker.customerName,
        amount: bmarker.amount,
    };
}

function billingMarker4API(bmarker) {
    return {
        id: bmarker?.id,
        projectId: bmarker?.project?.id,
        billingDate: bmarker.billingDate,
        // title: bmarker.title,
        // description: bmarker.description,
    };
}

export { billingMarker4Modal, billingMarker4API }
