<style lang="scss">
@import "modal";
</style>

<template>
  <transition name="modal" appear>
    <div class="modal_overlay" ref="overlay" tabindex="-1" @keydown.esc="$emit('close')">
      <div :class="['modal', 'modal_' + size]" v-click-outside="clickOutside">
        <div v-if="$slots.header" class="modal_header">
          <slot name="header"></slot>
        </div>
        <div :class="['modal_body', {'nopadding': bodyNoPadding}]">
          <slot></slot>
        </div>
        <div v-if="$slots.footer" class="modal_footer text-right">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    bodyNoPadding: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    clickOutDismiss: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickOutside(e) {
      if (!this.clickOutDismiss) return;
      if (e.target === this.$refs.overlay) this.$emit("close");
    },
  },
  mounted() {
    this.$refs.overlay.focus();
    const body = document.body;
    const scrollbarWidth = window.innerWidth - body.clientWidth;
    body.classList.add("modalopen");
    body.style.paddingRight = scrollbarWidth + "px";
  },
  destroyed() {
    // Would be better with after-leave transition hook but didn't work
    setTimeout(() => {
      const body = document.body;
      body.classList.remove("modalopen");
      body.style.paddingRight = 0;
    }, 100);
  }
};
</script>
