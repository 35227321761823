import dayjs from "dayjs";
import { mapGetters } from "vuex";
import IconAdd from "@/icons/plus-square-solid.svg";
import IconDelete from "@/icons/minus-square-solid.svg";
import BillingMarkerModal from "./BillingMarkerModal.vue";
import { moneyFormat } from "@/app/helpers";
import { billingMarker4Modal } from "./billing-marker-helpers";
import * as Config from "@/config/constants";

export default {
    name: "BillingMarkers",
    data: () => {
        return {
            modalOpen: false,
            bmarkerOpened: null,
            search: null,
            retrieveBtnDisabled: false,
        };
    },
    components: { IconAdd, IconDelete, BillingMarkerModal },
    computed: {
        // @ts-ignore
        ...mapGetters({
            billingMarkers: "Billing/crud/billingMarkers",
            user: "Resource/auth/user",
        })
    },
    methods: {
        open(bmarker) {
            if (bmarker.id) {
                // Edit
                this.bmarkerOpened = billingMarker4Modal(bmarker);
            }
            this.modalOpen = true;
        },
        close() {
            this.bmarkerOpened = null;
            this.modalOpen = false;
        },
        refresh() {
            return this.$store.dispatch("Billing/crud/getMarkers", this.search);
        },
        remove(bmarker) {
            if (!window.confirm("Êtes-vous sûr de vouloir supprimer ce jalon ?")) return;
            const vm = this;
            this.$store.dispatch("Billing/crud/delete", bmarker.id).then(() => {
                vm.refresh();
            });
        },
        billingDate(bmarker) {
            return dayjs(bmarker.billingDate.date).format(Config.DATE_FORMAT);
        },
        billingAmount(bmarker) {
            return moneyFormat(bmarker.amount);
        },
        retrieveBillingMarkers() {
            this.retrieveBtnDisabled = true;
            this.$store.dispatch("Billing/crud/retrieve").then(data => {
                console.info(data);
                this.refresh();
            }).finally(() => {
                this.retrieveBtnDisabled = false;
            });
        },
    },
    // mounted() {
    //     this.refresh();
    // },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.search = to.query.search;
            vm.refresh();
        });
    }
};
