import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import { moneyFormat } from "@/app/helpers";
import { billingMarker4API } from "./billing-marker-helpers";
import FormError from "@/views/common/FormError.vue";
import Modal from "@/views/modal/Modal.vue";

const defaultErrorMessages = {
    billingDate: null,
    title: null,
};

export default {
    name: "BillingMarkerModal",
    props: ["bmarker"],
    components: { Multiselect, FormError, FlatPickr, Modal },
    data() {
        return {
            bim: this.bmarker || {
                id: null,
                project: null,
                billingDate: null,
                title: null,
                description: null,
                amount: null
            },
            versions: [],
            errors: {
                billingDate: false,
                title: false,
            },
            errorMessages: defaultErrorMessages,
            dateConfig: {
                locale: French,
            },
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            projects: "Project/list/projects"
        }),
        billingAmount(bmarker) {
            return moneyFormat(this.bim.amount);
        },
    },
    methods: {
        loadLists() {
            this.$store.dispatch("Project/list/getList");
        },
        validate() {
            const requiredFields = ['billingDate', 'title'];
            requiredFields.forEach(requiredField => {
                this.errors[requiredField] = !this.bim[requiredField];
            });
            return !Object.values(this.errors).find(error => error);
        },
        submit(then) {
            if (!this.validate()) return;
            const bmarker = billingMarker4API(this.bim);
            this.$store.dispatch("Billing/crud/edit", bmarker).then(() => {
                this.errorMessages = defaultErrorMessages;
                then();
            }).catch(error => {
                if (error.response?.data?.errors) {
                    this.errorMessages = error.response?.data?.errors;
                }
            });
        },
        submitClose() {
            const vm = this;
            this.submit(() => {
                vm.$emit("saved");
                vm.close();
            })
        },
        close() {
            this.$emit("close");
        },
    },
    mounted() {
        this.$refs.amount.focus();
        this.loadLists();
    }
};
